import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { login, resendVerificationEmail, debugSession } from '../../Models/authModel';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import AuthSidebar from './AuthSidebar';
import './authStyles.css';

const LoginView = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [requiresVerification, setRequiresVerification] = useState(false);
    const [verificationEmail, setVerificationEmail] = useState('');
    const [resendingEmail, setResendingEmail] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);
    const [sessionStatus, setSessionStatus] = useState(null);
    const navigate = useNavigate();

    // Check session status on component mount
    useEffect(() => {
        const status = debugSession();
        setSessionStatus(status);
        
        // If already authenticated with valid session, redirect to home
        if (status.isAuthenticated && status.sessionExists && !status.isExpired) {
            navigate('/home');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        
        try {
            const google_signin = false;
            const response = await login(userName, password, google_signin);
            
            if(!response.success){
                if (response.requires_verification) {
                    setRequiresVerification(true);
                    setVerificationEmail(response.email);
                    setError(response.message);
                } else {
                    setError(response.message);
                }
                return;
            }
            
            // Check session after login
            const status = debugSession();
            setSessionStatus(status);
            
            if (!status.sessionExists || !status.userId) {
                setError('Login successful but session not properly set. Please try again.');
                return;
            }
            
            navigate('/home');
        } catch (error) {
            setError('An error occurred during login: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleResendVerification = async () => {
        if (!verificationEmail) {
            setError("Email address is required");
            return;
        }
        
        setResendingEmail(true);
        try {
            const response = await resendVerificationEmail(verificationEmail);
            if (response.success) {
                setResendSuccess(true);
                setError('');
            } else {
                setError(response.message || 'Failed to resend verification email');
            }
        } catch (error) {
            setError('An error occurred while resending verification email');
        } finally {
            setResendingEmail(false);
        }
    };

    // Update Google login handler
    const handleGoogleLogin = async (credentialResponse) => {
        setIsLoading(true);
        setError('');
        
        try {
            const decodedToken = jwtDecode(credentialResponse.credential);
            const email = decodedToken.email;
            const given_name = decodedToken.given_name;
            const family_name = decodedToken.family_name;
            const name = given_name + ' ' + family_name;
            const username = email.split('@')[0];
            const google_signin = true;
            const password = 'password';
            
            const response = await login(email, password, google_signin, name);
            
            if(response.success){
                // Check session after login
                const status = debugSession();
                setSessionStatus(status);
                
                if (!status.sessionExists || !status.userId) {
                    setError('Login successful but session not properly set. Please try again.');
                    return;
                }
                
                navigate('/home');
            } else {
                setError(response.message);
            }
        } catch (error) {
            setError('An error occurred during Google login: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex min-h-screen auth-container">
            {/* Left side with benefits and testimonials */}
            <AuthSidebar />
            
            {/* Right side with login form */}
            <div className="w-full lg:w-1/2 xl:w-2/5 flex items-center justify-center p-4 md:p-8">
                <div className="w-full max-w-md neomorphic-card p-8 space-y-6">
                    <div className="text-center mb-6">
                        <div className="genie-glow inline-block">
                            <h1 className="text-4xl font-bold text-indigo-700 mb-2 flex items-center justify-center">
                                <span className="text-5xl mr-2">🧞</span>ResumeGenie
                            </h1>
                        </div>
                        <p className="text-gray-600">Welcome back! Log in to your account</p>
                    </div>
                    
                    {error && (
                        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
                            <p className="text-red-700">{error}</p>
                        </div>
                    )}
                    
                    {resendSuccess && (
                        <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded">
                            <p className="text-green-700">Verification email sent! Please check your inbox.</p>
                        </div>
                    )}
                    
                    {requiresVerification && (
                        <div className="verification-container">
                            <p className="font-semibold text-indigo-700">Email verification required</p>
                            <p className="text-sm text-gray-600 mb-2">Please verify your email address to continue.</p>
                            <button 
                                onClick={handleResendVerification}
                                disabled={resendingEmail}
                                className="text-indigo-600 hover:text-indigo-800 text-sm font-medium focus:outline-none"
                            >
                                {resendingEmail ? 'Sending...' : 'Resend verification email'}
                            </button>
                        </div>
                    )}
                    
                    <div className="flex justify-center">
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                                <span className="ml-2 text-indigo-600">Logging in...</span>
                            </div>
                        ) : (
                            <div className="w-full">
                                <GoogleLogin
                                    onSuccess={handleGoogleLogin}
                                    onError={() => {
                                        console.log('Login Failed');
                                        setIsLoading(false);
                                        setError('Google login failed');
                                    }}
                                    theme="filled_blue"
                                    shape="pill"
                                    text="continue_with"
                                    useOneTap
                                />
                            </div>
                        )}
                    </div>
                    
                    <div className="divider">or</div>
                    
                    <form onSubmit={handleSubmit} className="space-y-5">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Username or Email</label>
                            <input
                                type="text"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                placeholder="Enter your email or username"
                                className="neomorphic-input w-full px-4 py-3 text-gray-700"
                                required
                            />
                        </div>
                        <div>
                            <div className="flex items-center justify-between mb-1">
                                <label className="block text-sm font-medium text-gray-700">Password</label>
                                <a href="#" className="text-xs text-indigo-600 hover:text-indigo-800">Forgot Password?</a>
                            </div>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                className="neomorphic-input w-full px-4 py-3 text-gray-700"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="neomorphic-button w-full py-3 font-medium text-white focus:outline-none"
                        >
                            {isLoading ? (
                                <div className="flex items-center justify-center">
                                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                                    <span className="ml-2">Logging in...</span>
                                </div>
                            ) : (
                                'Log in'
                            )}
                        </button>
                    </form>
                    
                    <div className="text-center space-y-2">
                        <p className="text-sm text-gray-600">
                            Don't have an account yet? <a href="/signup" className="text-indigo-600 hover:text-indigo-800 font-medium">Create for free</a>
                        </p>
                        <p className="text-sm text-gray-600">
                            Need to verify your email? <a href="/resend-verification" className="text-indigo-600 hover:text-indigo-800 font-medium">Resend verification email</a>
                        </p>
                    </div>
                </div>
            </div>
            
            {/* Add session debug info in development */}
            {process.env.NODE_ENV === 'development' && sessionStatus && (
                <div className="fixed bottom-4 right-4 bg-white p-4 rounded shadow-lg text-xs max-w-xs overflow-auto" style={{ maxHeight: '200px' }}>
                    <h3 className="font-bold mb-2">Session Debug</h3>
                    <pre>{JSON.stringify(sessionStatus, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default LoginView;