import React, { useState, useEffect } from 'react';

const AuthSidebar = () => {
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
    
    // Benefits of using ResumeGenie
    const benefits = [
        {
            icon: "✨",
            title: "AI-Powered Resume Generation",
            description: "Create professional resumes tailored to your industry in minutes"
        },
        {
            icon: "🔍",
            title: "Keyword Optimization",
            description: "Match your resume to job descriptions automatically"
        },
        //a hub to save different versions of your resume, dont have to start from scratch every time
        {
            icon: "📝",
            title: "Resume Hub",
            description: "Save and manage multiple versions of your resume"
        },
        //let ai create resume worthy content for you based on you rough draft
        {
            icon: "💡",
            title: "AI-Assisted Content Creation",
            description: "Let AI create resume-worthy content for you based on your rough draft"
        }
    ];
    
    // Testimonials from students and job seekers
    const testimonials = [
        {
            name: "Alex Johnson",
            role: "Software Engineer",
            text: "ResumeGenie helped me land interviews at 3 tech giants! The AI suggestions highlighted skills I didn't know were valuable."
        },
        {
            name: "Sarah Williams",
            role: "Recent Graduate",
            text: "As a first-time job seeker, I was lost until I found ResumeGenie. My resume now looks professional and I've received multiple callbacks!"
        },
        {
            name: "Michael Chen",
            role: "Marketing Professional",
            text: "The keyword optimization feature is a game-changer. My application response rate has increased by 70% since using ResumeGenie."
        },
        {
            name: "Priya Patel",
            role: "Healthcare Professional",
            text: "ResumeGenie understood my career transition needs perfectly. The tailored suggestions helped me showcase transferable skills effectively."
        },

    ];
    
    // Auto-scroll testimonials
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTestimonialIndex((prevIndex) => 
                prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000); // Change testimonial every 5 seconds
        
        return () => clearInterval(interval);
    }, [testimonials.length]);
    
    return (
        <div className="hidden lg:flex lg:w-1/2 xl:w-3/5 bg-gradient-to-br from-indigo-600 via-purple-600 to-indigo-800 p-12 flex-col justify-between">
            <div>
                <div className="mb-8">
                    <h1 className="text-5xl font-bold text-white mb-2 flex items-center">
                        <span className="text-6xl mr-2">🧞</span>ResumeGenie
                    </h1>
                    <p className="text-indigo-100 text-xl">Turn your experience into opportunity with AI magic</p>
                </div>
                
                <div className="space-y-8 mt-12">
                    {benefits.map((benefit, index) => (
                        <div key={index} className="flex items-start">
                            <div className="flex-shrink-0 bg-white bg-opacity-20 rounded-full p-3 mr-4 shadow-inner">
                                <span className="text-2xl">{benefit.icon}</span>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-white">{benefit.title}</h3>
                                <p className="text-indigo-100">{benefit.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
            <div className="mt-auto">
                <h3 className="text-white text-xl font-semibold mb-4">What our users say</h3>
                <div className="bg-white bg-opacity-10 rounded-xl p-6 shadow-inner backdrop-blur-sm">
                    <div className="h-40 overflow-hidden relative">
                        {testimonials.map((testimonial, index) => (
                            <div 
                                key={index} 
                                className={`absolute w-full transition-opacity duration-1000 ${
                                    index === currentTestimonialIndex ? 'opacity-100' : 'opacity-0'
                                }`}
                            >
                                <p className="text-white italic mb-4">"{testimonial.text}"</p>
                                <div className="flex items-center">
                                    <div className="w-10 h-10 rounded-full bg-indigo-300 flex items-center justify-center text-indigo-800 font-bold mr-3">
                                        {testimonial.name.charAt(0)}
                                    </div>
                                    <div>
                                        <p className="text-white font-semibold">{testimonial.name}</p>
                                        <p className="text-indigo-200 text-sm">{testimonial.role}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthSidebar; 