import React, { useState, useRef, useEffect } from 'react';
import { Plus, Minus, Settings, Move } from 'lucide-react';
import Resume from './ResumeReview';

const ResumePreview = ({ setfullView, personalInfo, socials, education, workExperience, projects, skills, achievements, certifications, leadership, extracurriculars, summary, name, sections, keywords, customSections, customSectionData, margins, setMargins }) => {
  const [zoom, setZoom] = useState(80);
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const contentRef = useRef(null);
  
  let { margin_left, margin_right, margin_top } = margins || {};

  // Always enable dragging (removed the threshold)
  const isDraggable = true;

  const handleZoomIn = () => {
    setZoom(prevZoom => Math.min(prevZoom + 10, 200));
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => Math.max(prevZoom - 10, 10));
  };

  // New reset function
  const handleReset = () => {
    setPosition({ x: 0, y: 0 });
    setZoom(60);
  };

  // New handler for mouse wheel zoom
  const handleWheel = (e) => {
    e.preventDefault();
    
    if (e.deltaY < 0) {
      // Scrolling up - zoom in
      setZoom(prevZoom => Math.min(prevZoom + 5, 200));
    } else {
      // Scrolling down - zoom out
      setZoom(prevZoom => Math.max(prevZoom - 5, 10));
    }
  };

  const handleMarginChange = (type, value) => {
    // Ensure value is between 0 and 10
    const numValue = Math.max(0, Math.min(10, parseInt(value) || 0));
    setMargins(prev => ({
      ...prev,
      [type]: numValue
    }));
  };
  
  // Mouse event handlers for dragging
  const handleMouseDown = (e) => {
    // Prevent default to avoid text selection during drag
    e.preventDefault();
    
    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
    
    // Change cursor style
    document.body.style.cursor = 'grabbing';
  };

  // Add and remove event listeners
  useEffect(() => {
    const handleGlobalMouseMove = (e) => {
      if (isDragging) {
        e.preventDefault();
        setPosition({
          x: e.clientX - dragStart.x,
          y: e.clientY - dragStart.y
        });
      }
    };
    
    const handleGlobalMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
        document.body.style.cursor = 'default';
      }
    };
    
    window.addEventListener('mousemove', handleGlobalMouseMove);
    window.addEventListener('mouseup', handleGlobalMouseUp);
    
    return () => {
      window.removeEventListener('mousemove', handleGlobalMouseMove);
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isDragging, dragStart]);

  return (
    <div className="md:w-2/3 h-[80vh] overflow-hidden border border-gray-300 rounded-lg shadow-md relative">
      {/* Simplified debug bar with Reset button */}
      {process.env.NODE_ENV === 'development' && (
      <div className="absolute top-0 left-0 right-0 bg-gray-50 border-b p-2 z-30 flex justify-between items-center text-xs">
        
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <span className="font-medium">Current Zoom:</span>
              <span className="px-2 py-1 bg-white rounded border">{zoom}%</span>
            </div>
            
            <div className="flex items-center space-x-2">
              <span className="font-medium">Drag & Scroll to Zoom:</span>
              <span className="px-2 py-1 rounded border bg-green-100 border-green-300 text-green-800">
                Enabled
              </span>
            </div>
          </div>
        

       
      </div>
      )}

      <div className="absolute top-12 right-2 flex space-x-2 z-20">
         {/* Reset button */}
         <button 
          onClick={handleReset}
          className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors text-xs font-medium z-50"
        >
          Reset View
        </button>

        <button 
          onClick={handleZoomOut}
          className="p-1 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
          aria-label="Zoom out"
        >
          <Minus size={16} />
        </button>
        
        {/* Zoom percentage indicator */}
        <div className="px-2 py-1 bg-gray-100 rounded border border-gray-300 text-xs font-medium flex items-center">
          {zoom}%
        </div>
        
        <button 
          onClick={handleZoomIn}
          className="p-1 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
          aria-label="Zoom in"
        >
          <Plus size={16} />
        </button>
      </div>
      
      <div className="absolute top-12 left-2 z-20 flex space-x-2">
        <div className="relative">
          <button 
            onClick={() => setIsLayoutOpen(!isLayoutOpen)}
            className="p-1 bg-gray-200 rounded hover:bg-gray-300 transition-colors flex items-center space-x-1"
          >
            <Settings size={16} />
            <span>Layout</span>
          </button>
          
          {isLayoutOpen && (
            <div className="absolute top-full left-0 mt-1 bg-white border border-gray-200 rounded-md shadow-lg p-3 w-56">
              <h3 className="font-medium mb-3">Page Margins</h3>
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <label className="text-sm text-gray-600">Left:</label>
                  <input
                    type="number"
                    min="0"
                    max="10"
                    value={margin_left}
                    onChange={(e) => handleMarginChange('margin_left', e.target.value)}
                    className="w-16 p-1 border rounded text-sm"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <label className="text-sm text-gray-600">Right:</label>
                  <input
                    type="number"
                    min="0"
                    max="10"
                    value={margin_right}
                    onChange={(e) => handleMarginChange('margin_right', e.target.value)}
                    className="w-16 p-1 border rounded text-sm"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <label className="text-sm text-gray-600">Top:</label>
                  <input
                    type="number"
                    min="0"
                    max="10"
                    value={margin_top}
                    onChange={(e) => handleMarginChange('margin_top', e.target.value)}
                    className="w-16 p-1 border rounded text-sm"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <button 
          onClick={() => setfullView(true)}
          className="p-1 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
          aria-label="Full view"
        >
          Full View
        </button>
      </div>

      {/* Draggable indicator is now always shown */}
      <div className="absolute bottom-2 right-2 z-20 flex items-center bg-white p-1 rounded-md border shadow-sm text-xs text-gray-600">
        <Move size={14} className="mr-1" />
        <span>Drag to move • Scroll to zoom</span>
      </div>

      {/* Main container with wheel event for zooming */}
      <div 
        className="w-full h-full relative pt-10"
        style={{
          overflow: 'hidden',
          cursor: isDragging ? 'grabbing' : 'grab'
        }}
        onMouseDown={handleMouseDown}
        onWheel={handleWheel}
      >
        <div 
          ref={contentRef}
          className="absolute inset-0 pt-10"
          style={{
            transform: `translate(${position.x}px, ${position.y}px)`,
            transition: isDragging ? 'none' : 'transform 0.2s ease-out'
          }}
        >
          <div 
            className="flex justify-center items-start py-8 px-4"
          >
            <div 
              className="w-fit"
              style={{
                transform: `scale(${zoom / 100})`,
                transformOrigin: 'center top',
                transition: 'transform 0.2s ease-in-out'
              }}
            >
              <Resume
                previewMode={true}
                previewData={{
                  personalInfo,
                  socials,
                  education,
                  workExperience,
                  projects,
                  skills,
                  achievements,
                  certifications,
                  leadership,
                  extracurriculars,
                  name,
                  sections,
                  summary,
                  keywords,
                  customSections,
                  customSectionData,
                  margins
                }}
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Click outside handler */}
      {isLayoutOpen && (
        <div 
          className="fixed inset-0 z-10"
          onClick={() => setIsLayoutOpen(false)}
        />
      )}
    </div>
  );
};

export default ResumePreview;