import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resendVerificationEmail } from '../../Models/authModel';
import AuthSidebar from './AuthSidebar';
import './authStyles.css';

const ResendVerification = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('idle'); // idle, loading, success, error
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!email) {
            setStatus('error');
            setMessage('Please enter your email address');
            return;
        }
        
        setStatus('loading');
        
        try {
            const response = await resendVerificationEmail(email);
            
            if (response.success) {
                setStatus('success');
                setMessage('Verification email sent! Please check your inbox.');
                // Clear the email field
                setEmail('');
            } else {
                setStatus('error');
                setMessage(response.message || 'Failed to send verification email');
            }
        } catch (error) {
            setStatus('error');
            setMessage('An error occurred. Please try again later.');
        }
    };

    const handleGoToLogin = () => {
        navigate('/login');
    };

    return (
        <div className="flex min-h-screen auth-container">
            {/* Left side with benefits and testimonials */}
            <AuthSidebar />
            
            {/* Right side with resend verification form */}
            <div className="w-full lg:w-1/2 xl:w-2/5 flex items-center justify-center p-4 md:p-8">
                <div className="w-full max-w-md neomorphic-card p-8 space-y-6">
                    <div className="text-center mb-6">
                        <div className="genie-glow inline-block">
                            <h1 className="text-4xl font-bold text-indigo-700 mb-2 flex items-center justify-center">
                                <span className="text-5xl mr-2">🧞</span>ResumeGenie
                            </h1>
                        </div>
                        <p className="text-gray-600">Resend Verification Email</p>
                    </div>
                    
                    {status === 'success' && (
                        <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded">
                            <p className="text-green-700 font-medium">Success!</p>
                            <p className="text-green-600">{message}</p>
                        </div>
                    )}
                    
                    {status === 'error' && (
                        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
                            <p className="text-red-700 font-medium">Error</p>
                            <p className="text-red-600">{message}</p>
                        </div>
                    )}
                    
                    <div className="mx-auto w-20 h-20 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                    </div>
                    
                    <p className="text-center text-gray-600">
                        Enter your email address below and we'll send you a new verification link.
                    </p>
                    
                    <form onSubmit={handleSubmit} className="space-y-5">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email address"
                                className="neomorphic-input w-full px-4 py-3 text-gray-700"
                                required
                            />
                        </div>
                        
                        <button
                            type="submit"
                            disabled={status === 'loading'}
                            className="neomorphic-button w-full py-3 font-medium text-white focus:outline-none"
                        >
                            {status === 'loading' ? (
                                <div className="flex items-center justify-center">
                                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                                    <span className="ml-2">Sending...</span>
                                </div>
                            ) : (
                                'Resend Verification Email'
                            )}
                        </button>
                    </form>
                    
                    <div className="text-center">
                        <button
                            onClick={handleGoToLogin}
                            className="text-indigo-600 hover:text-indigo-800 font-medium"
                        >
                            Back to Login
                        </button>
                    </div>
                    
                    <div className="text-center text-sm text-gray-500 mt-4">
                        <p>
                            For security reasons, we don't confirm whether an email exists in our system.
                            If you have an account with this email, you will receive a verification link.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResendVerification; 