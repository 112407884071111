import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Wand2, ChevronDown } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import currentApiUrl from "../Models/apiUrl"; // Adjust the import path as needed
import { refreshSession } from '../Models/authModel'; // Import refreshSession

const AIEnhancedEditor = ({ 
    index, 
    content, 
    onContentChange, 
    label = "Content",
    linecount = 1,
    characterLimit = 0,
    isDescriptionEmpty,
    apiEndpoint = 'enhance_content', // New parameter for specifying the API endpoint
    contentType = 'general' // New parameter for specifying the content type
  }) => {
    const quillRef = useRef(null);
    const [customPrompt, setCustomPrompt] = useState('');
    const [lineCount, setLineCount] = useState(linecount);
    const [charCount, setCharCount] = useState(characterLimit);
    const [animateAIRewrite, setAnimateAIRewrite] = useState(false);
    const [animationProgress, setAnimationProgress] = useState(0);
    const [descriptionGenerated, setDescriptionGenerated] = useState(true);
    const [isGenerating, setIsGenerating] = useState(false);
    const [userId, setUserId] = useState(null);
    const [error, setError] = useState(null);
    const [enhanceMode, setEnhanceMode] = useState('general');
    const [selectedText, setSelectedText] = useState({ text: '', range: null });
    const [selectionPosition, setSelectionPosition] = useState(null);
    const [showSelectionPromptInput, setShowSelectionPromptInput] = useState(false);
    const [selectionCustomPrompt, setSelectionCustomPrompt] = useState('');
    const [storedSelection, setStoredSelection] = useState({ text: '', range: null });
    const [highlightedRange, setHighlightedRange] = useState(null);
    const [originalFormats, setOriginalFormats] = useState(null);
    const [textareaHeight, setTextareaHeight] = useState('auto');
    const [originalContent, setOriginalContent] = useState('');
    const [modifiedRange, setModifiedRange] = useState(null);
    const [showAcceptReject, setShowAcceptReject] = useState(false);
    const [aiGeneratedText, setAiGeneratedText] = useState('');
    
    const debounceTimer = useRef(null);
    const lastSelectionRef = useRef(null);
    const textareaRef = useRef(null);

    const [rewriteJustCompleted, setRewriteJustCompleted] = useState(false);

    // Helper function to clear any and all highlighting in the document
    const clearAllHighlighting = () => {
      console.log('Clearing all highlighting');
      if (!quillRef.current) return;
      
      const quill = quillRef.current.getEditor();
      const fullText = quill.getText();
      
      // Remove all background formatting
      quill.formatText(
        0,
        fullText.length,
        { background: false }
      );
      
      // Reset highlight tracking states
      setHighlightedRange(null);
    };

    useEffect(() => {
      const getUserIdFromSession = () => {
        try {
          const sessionData = localStorage.getItem('session');
          if (!sessionData) {
            setError('No active session found');
            return null;
          }
          
          const session = JSON.parse(sessionData);
          if (!session.user_id) {
            setError('User ID not found in session');
            return null;
          }
          
          refreshSession();
          
          return session.user_id;
        } catch (error) {
          console.error('Error getting user ID from session:', error);
          setError('Error retrieving user information');
          return null;
        }
      };
      
      const id = getUserIdFromSession();
      setUserId(id);
    }, []);

    useEffect(() => {
      let animationFrame;
      let startTime;
      const animationDuration = 3000;

      if (animateAIRewrite) {
        startTime = Date.now();
        const animate = () => {
          const elapsed = Date.now() - startTime;
          const progress = Math.min(elapsed / animationDuration * 100, 99);
          
          setAnimationProgress(progress);
          
          if (progress < 99) {
            animationFrame = requestAnimationFrame(animate);
          }
        };
        animationFrame = requestAnimationFrame(animate);
      }
      
      return () => {
        if (animationFrame) {
          cancelAnimationFrame(animationFrame);
        }
      };
    }, [animateAIRewrite]);

    const getSelectedText = () => {
      if (!quillRef.current) return { text: '', range: null };
      
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      
      if (!range || range.length === 0) return { text: '', range: null };
      
      const text = quill.getText(range.index, range.length);
      return { text, range };
    };

    const getSurroundingContext = (range) => {
      if (!quillRef.current || !range) return '';
      
      const quill = quillRef.current.getEditor();
      const fullText = quill.getText();
      
      const contextBefore = fullText.substring(Math.max(0, range.index - 100), range.index);
      const contextAfter = fullText.substring(range.index + range.length, Math.min(fullText.length, range.index + range.length + 100));
      
      return contextBefore + '...' + contextAfter;
    };

    const getSelectionPositionStable = useCallback(() => {
      if (!quillRef.current) return null;
      
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      
      if (!range || range.length === 0) return null;
      
      const startBounds = quill.getBounds(range.index);
      const endBounds = quill.getBounds(range.index + range.length);
      
      const editorRect = quill.container.getBoundingClientRect();
      
      const isMultiLine = endBounds.top > startBounds.top + 5;
      
      const buttonWidth = 150;
      const buttonHeight = 40;
      
      let top, left;
      
      // Calculate vertical center position
      if (isMultiLine) {
        try {
          // For multi-line, put near the middle of the selection's vertical space
          const midPoint = startBounds.top + (endBounds.top - startBounds.top) / 2;
          top = editorRect.top + midPoint - (buttonHeight / 2);
          
          // For right side placement, use the end bounds' right position
          left = editorRect.left + endBounds.left + endBounds.width + 5; // 5px padding
        } catch (e) {
          // Fallback if there's an error calculating positions
          top = editorRect.top + startBounds.top + (buttonHeight / 2);
          left = editorRect.left + startBounds.left + startBounds.width + 5;
        }
      } else {
        // For single-line selection, center vertically and place at right edge
        top = editorRect.top + startBounds.top + (startBounds.height / 2) - (buttonHeight / 2);
        left = editorRect.left + endBounds.left + endBounds.width + 5;
      }
      
      // Ensure button stays within viewport bounds
      const maxTop = window.innerHeight - (buttonHeight + 20);
      const maxLeft = window.innerWidth - (buttonWidth + 20);
      const minTop = Math.max(editorRect.top, 10);
      const minLeft = Math.max(editorRect.left, 10);
      
      if (top < minTop) {
        top = minTop;
      }
      
      if (top > maxTop) {
        top = maxTop;
      }
      
      if (left < minLeft) {
        left = minLeft;
      }
      
      if (left > maxLeft) {
        left = maxLeft;
      }
      
      return {
        top: top,
        left: left,
      };
    }, []);

    const getSelectionPosition = getSelectionPositionStable;

    const updateSelectionUI = (range, text, position) => {
      lastSelectionRef.current = { range, text, position };
      
      if (!range || range.length === 0) {
        if (!showSelectionPromptInput) {
          setSelectionPosition(null);
        }
        return;
      }
      
      if (text.length >= 5 || text.includes(' ')) {
        if (!selectionPosition || 
            Math.abs(selectionPosition.top - position.top) > 5 || 
            Math.abs(selectionPosition.left - position.left) > 5) {
          setSelectionPosition(position);
        }
        
        setSelectedText({ text, range });
      } else {
        if (!showSelectionPromptInput) {
          setSelectionPosition(null);
        }
      }
    };

    useEffect(() => {
      if (!quillRef.current) return;
      
      const quill = quillRef.current.getEditor();
      
      const handleSelectionChange = (range) => {
        if (debounceTimer.current) {
          clearTimeout(debounceTimer.current);
        }
        
        if (rewriteJustCompleted) {
          return;
        }
        
        if (showSelectionPromptInput && lastSelectionRef.current && range) {
          if (Math.abs((lastSelectionRef.current.range?.index || 0) - range.index) < 5 &&
              Math.abs((lastSelectionRef.current.range?.length || 0) - range.length) < 5) {
            return;
          }
        }
        
        if (!range || range.length === 0) {
          if (showSelectionPromptInput) {
            return;
          }
          
          debounceTimer.current = setTimeout(() => {
            const currentRange = quill.getSelection();
            if (!currentRange || currentRange.length === 0) {
              setSelectionPosition(null);
            }
          }, 100);
          return;
        }
        
        const text = quill.getText(range.index, range.length);
        const position = getSelectionPosition();
        
        debounceTimer.current = setTimeout(() => {
          updateSelectionUI(range, text, position);
        }, 50);
      };
      
      quill.on('selection-change', handleSelectionChange);
      
      return () => {
        if (debounceTimer.current) {
          clearTimeout(debounceTimer.current);
        }
        quill.off('selection-change', handleSelectionChange);
      };
    }, [quillRef.current, showSelectionPromptInput, selectionPosition, rewriteJustCompleted]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!showSelectionPromptInput) return;
        
        const selectionContainer = document.querySelector('.selection-floating-container');
        if (selectionContainer && selectionContainer.contains(event.target)) {
          return;
        }
        
        setShowSelectionPromptInput(false);
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showSelectionPromptInput]);

    const applyEnhancedTextToSelection = (enhancedText, range) => {
      if (!range) {
        // Store original content before making changes
        setOriginalContent(content);
        // Store the AI-generated content
        setAiGeneratedText(enhancedText);
        onContentChange(index, enhancedText);
        return;
      }
      
      // Store original content and range before modification
      setOriginalContent(content);
      setModifiedRange(range);
      // Store the original text at the selection range
      const quill = quillRef.current.getEditor();
      const originalSelectedText = quill.getText(range.index, range.length);
      setStoredSelection({
        text: originalSelectedText,
        range: range
      });
      // Store the AI-generated content
      setAiGeneratedText(enhancedText);
      
      quill.focus();
      quill.deleteText(range.index, range.length);
      
      if (enhancedText.includes('<') && enhancedText.includes('>')) {
        quill.clipboard.dangerouslyPasteHTML(range.index, enhancedText);
        setTimeout(() => {
          // Get the length of the inserted content
          const insertedRange = {
            index: range.index,
            length: getInsertedTextLength(enhancedText)
          };
          
          // Apply highlighting to only the inserted AI text
          quill.formatText(
            insertedRange.index,
            insertedRange.length,
            { background: 'rgba(66, 153, 225, 0.2)' } // Light blue background
          );
          
          // Store the modified range for accept/reject
          setModifiedRange(insertedRange);
          setShowAcceptReject(true);
        }, 50);
      } else {
        quill.insertText(range.index, enhancedText);
        
        setTimeout(() => {
          // Apply highlighting to only the inserted AI text
          quill.formatText(
            range.index,
            enhancedText.length,
            { background: 'rgba(66, 153, 225, 0.2)' } // Light blue background
          );
          
          // Update the modifiedRange with the new content length
          setModifiedRange({
            index: range.index,
            length: enhancedText.length
          });
          setShowAcceptReject(true);
        }, 50);
      }
    };

    // Helper function to estimate the length of inserted HTML text
    const getInsertedTextLength = (html) => {
      // Create a temporary div to calculate text length
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      const textLength = tempDiv.textContent.length;
      return textLength;
    };

    // Function to accept the AI-generated content
    const acceptChanges = () => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        
        // Clear all highlighting from the document
        clearAllHighlighting();
        
        // Reset state
        setModifiedRange(null);
        setShowAcceptReject(false);
        setOriginalContent('');
        setAiGeneratedText('');
      }
    };

    // Function to reject the AI-generated content
    const rejectChanges = () => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        
        if (modifiedRange && storedSelection.text) {
          // For selection-based enhancing, replace just the modified portion
          console.log('Rejecting selection change. Replacing:', quill.getText(modifiedRange.index, modifiedRange.length));
          console.log('With original text:', storedSelection.text);
          
          // First remove the modified content
          quill.deleteText(modifiedRange.index, modifiedRange.length);
          
          // Then insert the original text
          quill.insertText(modifiedRange.index, storedSelection.text);
          
          // We've modified the Quill editor directly, so we need to update the parent component
          // Get the full content after our changes
          const updatedContent = quill.root.innerHTML;
          onContentChange(index, updatedContent);
          
          // Clear all highlighting AFTER content is updated
          setTimeout(() => {
            clearAllHighlighting();
          }, 0);
        } else if (originalContent) {
          // For full content replacement
          console.log('Rejecting full content change, restoring original');
          
          // Replace entire content with original
          quill.setContents([]);
          quill.clipboard.dangerouslyPasteHTML(originalContent);
          
          // Update parent component state
          onContentChange(index, originalContent);
          
          // Clear all highlighting AFTER content is updated
          setTimeout(() => {
            clearAllHighlighting();
          }, 0);
        }
        
        // Reset all state variables
        setModifiedRange(null);
        setShowAcceptReject(false);
        setOriginalContent('');
        setAiGeneratedText('');
        setStoredSelection({ text: '', range: null });
      }
    };

    const handleAIRewrite = async (prompt = '', mode = 'general') => {
      if (!userId) {
        console.error('Cannot make API call: No user ID available');
        setError('User authentication required');
        return;
      }
      
      // Hide any existing accept/reject UI before starting a new rewrite
      setShowAcceptReject(false);
      
      // Clear any existing highlighting before starting a new enhancement
      clearAllHighlighting();
      
      setIsGenerating(true);
      setAnimateAIRewrite(true);
      setAnimationProgress(0);
      setError(null);
      
      const selection = showSelectionPromptInput ? storedSelection : getSelectedText();
      const isSelectionMode = selection.text !== '';
      
      // Store original content before modification
      setOriginalContent(content);
      
      // For selection mode, ensure we properly store the original text
      if (isSelectionMode && selection.range && quillRef.current) {
        const quill = quillRef.current.getEditor();
        const originalSelectedText = quill.getText(selection.range.index, selection.range.length);
        setStoredSelection({
          text: originalSelectedText,
          range: selection.range
        });
      }
      
      try {
        let apiUrl;
        let requestBody;
        
        if (isSelectionMode) {
          apiUrl = `${currentApiUrl}/rephrase_selected_text`;
          const surroundingContext = getSurroundingContext(selection.range);
          
          requestBody = {
            user_id: userId,
            selected_text: selection.text,
            surrounding_context: surroundingContext
          };
          
          if (prompt) {
            requestBody.prompt = prompt;
          }
          
          console.log('Using selection rewrite endpoint with selection:', selection.text);
        } else {
          apiUrl = `${currentApiUrl}/${apiEndpoint}`;
          requestBody = {
            user_id: userId,
            content: content,
            content_type: contentType,
            lines_count: lineCount,
            character_limit: charCount
          };
          
          if (prompt) {
            requestBody.prompt = prompt;
          }
        }

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          console.log(response);
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();
        let enhancedContent;
        
        if (isSelectionMode) {
          enhancedContent = data.rephrased_text;
          console.log('Rephrased text for selection:', enhancedContent);
          
          // Store the AI-generated text
          setAiGeneratedText(enhancedContent);
          
          const currentRange = selection.range;
          
          applyEnhancedTextToSelection(enhancedContent, currentRange);
          
          setRewriteJustCompleted(true);
          setSelectionPosition(null);
        } else {
          enhancedContent = data.enhanced_content || data.rephrased_description;
          
          // Store the AI-generated text
          setAiGeneratedText(enhancedContent);
          
          onContentChange(index, enhancedContent);
          
          // For full content replacement, explicitly set modifiedRange to null
          setModifiedRange(null);
          
          // For full content replacement, highlight the entire content
          setTimeout(() => {
            if (quillRef.current) {
              const quill = quillRef.current.getEditor();
              const fullText = quill.getText();
              
              // Apply highlighting to the entire text
              quill.formatText(
                0,
                fullText.length,
                { background: 'rgba(66, 153, 225, 0.2)' } // Light blue background
              );
              
              setShowAcceptReject(true);
            }
          }, 50);
        }

      } catch (error) {
        console.error('Error enhancing content:', error);
        setError('Failed to enhance content. Please try again.');
      } finally {
        setIsGenerating(false);
        setShowSelectionPromptInput(false);
        setAnimationProgress(100);
        setTimeout(() => {
          setAnimateAIRewrite(false);
          setAnimationProgress(0);
          setStoredSelection({ text: '', range: null });
          
          setTimeout(() => {
            setRewriteJustCompleted(false);
          }, 1000);
        }, 500);
      }
    };

    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        ['clean']
      ]
    };

    const quillStyles = `
     .quill-custom .ql-container {
         min-height: 100px;
         max-height: 400px;
         overflow-y: auto;
         border-bottom-left-radius: 8px;
         border-bottom-right-radius: 8px;
     }
     .quill-custom .ql-toolbar {
         border-top-left-radius: 8px;
         border-top-right-radius: 8px;
     }
     .quill-custom {
         position: relative;
     }
     .enhance-dropdown {
       position: relative;
       display: inline-block;
     }
     .enhance-dropdown-content {
       position: absolute;
       background-color: white;
       min-width: 200px;
       box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
       z-index: 10;
       border-radius: 8px;
       overflow: hidden;
     }
     .enhance-dropdown-item {
       padding: 12px 16px;
       text-decoration: none;
       display: block;
       cursor: pointer;
       transition: background-color 0.2s;
     }
     .enhance-dropdown-item:hover {
       background-color: #f1f5f9;
     }
     .selection-floating-container {
       position: fixed;
       z-index: 999;
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       animation: fadeIn 0.3s ease-in-out;
       transform: translateZ(0);
       will-change: transform;
       pointer-events: auto;
       backface-visibility: hidden;
       filter: drop-shadow(0 2px 8px rgba(249, 168, 38, 0.3));
     }
     @keyframes fadeIn {
       from { opacity: 0; transform: translateY(5px); }
       to { opacity: 1; transform: translateY(0); }
     }
     .selection-buttons-row {
       display: flex;
       align-items: stretch;
       border-radius: 4px;
       overflow: hidden;
     }
     .selection-input-container {
       background-color: white;
       border-radius: 4px;
       padding: 8px;
       width: 280px;
       box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
     }
     .selection-prompt-input {
       width: 100%;
       padding: 8px;
       border: 1px solid #ddd;
       border-radius: 4px;
       background-color: white;
       font-size: 14px;
       outline: none;
       overflow-y: auto;
       word-wrap: break-word;
       line-height: 1.4;
       font-family: inherit;
     }
     .selection-prompt-input:focus {
       border-color: #f9a826;
       box-shadow: 0 0 0 2px rgba(249, 168, 38, 0.2);
     }
     .selection-prompt-buttons {
       display: flex;
       justify-content: space-between;
       margin-top: 8px;
       gap: 5px;
     }
     .selection-prompt-button {
       padding: 6px 12px;
       border-radius: 4px;
       font-size: 12px;
       cursor: pointer;
       font-weight: 500;
     }
     .selection-apply-button {
       background-color: #f9a826;
       color: white;
       border: none;
       flex-grow: 1;
       display: flex;
       align-items: center;
       justify-content: center;
     }
     .selection-apply-button:hover {
       background-color: #f59f00;
     }
     .selection-cancel-button {
       background-color: #f1f5f9;
       color: #64748b;
       border: 1px solid #e2e8f0;
     }
     .selection-cancel-button:hover {
       background-color: #e2e8f0;
     }
     .selection-dropdown-toggle {
       background-color: #f9a826;
       color: white;
       border: none;
       width: 32px;
       padding: 0 8px;
       display: flex;
       align-items: center;
       justify-content: center;
       cursor: pointer;
       transition: all 0.2s;
       border-left: 1px solid rgba(255, 255, 255, 0.3);
       position: relative;
     }
     .selection-dropdown-toggle:hover {
       background-color: #f59f00;
     }
     .selection-dropdown-toggle::after {
       content: '';
       position: absolute;
       bottom: 4px;
       left: 50%;
       transform: translateX(-50%);
       width: 4px;
       height: 4px;
       background-color: white;
       border-radius: 50%;
     }
     .ai-enhance-button {
       display: flex;
       align-items: center;
       background-color: #f9a826;
       color: white;
       padding: 6px 12px;
       font-weight: 500;
       border: none;
       cursor: pointer;
       transition: all 0.2s;
     }
     .ai-enhance-button:hover {
       background-color: #f59f00;
     }
     @keyframes shimmer {
       0% {
         background-position: -200% 0;
       }
       100% {
         background-position: 200% 0;
       }
     }
     .animate-enhancement {
       position: absolute;
       inset: 0;
       overflow: hidden;
     }
 `;

    // Add key event handler to detect backspace and other text-modifying keys
    useEffect(() => {
      if (!quillRef.current) return;
      
      const quill = quillRef.current.getEditor();
      
      const handleKeyDown = (event) => {
        // Check for keys that modify text like backspace, delete, etc.
        if (['Backspace', 'Delete'].includes(event.key) && !showSelectionPromptInput) {
          // Hide the selection UI on text deletion only if not in custom prompt mode
          setSelectionPosition(null);
          setShowSelectionPromptInput(false);
        }
      };
      
      quill.root.addEventListener('keydown', handleKeyDown);
      
      return () => {
        quill.root.removeEventListener('keydown', handleKeyDown);
      };
    }, [quillRef.current, showSelectionPromptInput]);

    // Apply persistent highlighting when in custom prompt mode
    useEffect(() => {
      if (!quillRef.current) return;

      const quill = quillRef.current.getEditor();
      
      // When showing the selection prompt input, apply highlighting to the selection
      if (showSelectionPromptInput && storedSelection.range) {
        // First, store the original formats for the range
        const formats = quill.getFormat(storedSelection.range.index, storedSelection.range.length);
        setOriginalFormats(formats);
        
        // Apply highlighting to the selection (only adds background, preserves other formats)
        quill.formatText(
          storedSelection.range.index, 
          storedSelection.range.length, 
          { background: 'rgba(249, 168, 38, 0.2)' } // Light orange background
        );
        
        // Store the highlighted range so we can clear it later
        setHighlightedRange(storedSelection.range);
      } 
      // When hiding the selection prompt input, clear highlighting only (preserve other formats)
      else if (!showSelectionPromptInput && highlightedRange) {
        // Only remove the background format, not all formats
        quill.formatText(
          highlightedRange.index, 
          highlightedRange.length, 
          { background: false }
        );
        setHighlightedRange(null);
      }
    }, [showSelectionPromptInput, storedSelection]);

    // Add a function to handle textarea auto-resize
    const autoResizeTextarea = (element) => {
      if (!element) return;
      
      // Reset height temporarily to get the correct scrollHeight
      element.style.height = 'auto';
      
      // Set the height directly based on scrollHeight
      const newHeight = Math.min(Math.max(element.scrollHeight, 60), 200); // Min 60px, max 200px
      element.style.height = `${newHeight}px`;
      setTextareaHeight(`${newHeight}px`);
    };

    // Add useEffect to handle textarea resize when content changes
    useEffect(() => {
      if (textareaRef.current) {
        autoResizeTextarea(textareaRef.current);
      }
    }, [selectionCustomPrompt, showSelectionPromptInput]);

    useEffect(() => {
      if (!quillRef.current) return;
      
      const quill = quillRef.current.getEditor();
      
      const handleTextChange = () => {
        // Clear accept/reject UI when user manually edits the text
        if (showAcceptReject) {
          setShowAcceptReject(false);
          
          // Clear any highlighting
          if (modifiedRange) {
            quill.formatText(
              modifiedRange.index,
              modifiedRange.length,
              { background: false }
            );
          } else {
            // For full content replacement, clear highlighting from the entire content
            const fullText = quill.getText();
            quill.formatText(
              0,
              fullText.length,
              { background: false }
            );
          }
          
          setModifiedRange(null);
          setAiGeneratedText('');
        }
      };
      
      quill.on('text-change', handleTextChange);
      
      return () => {
        quill.off('text-change', handleTextChange);
      };
    }, [quillRef.current, showAcceptReject, modifiedRange]);

    return (
      <div>
        <style>{quillStyles}</style>
        <label htmlFor={`content-${index}`} className="block mb-2 text-sm font-medium text-gray-900">
          {label}
        </label>
        
        {error && (
          <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
            <p>{error}</p>
          </div>
        )}
        
        <div className="mb-2 flex justify-end">
          <button
            onClick={() => handleAIRewrite('', 'general')}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center"
            disabled={isGenerating}
          >
            {isGenerating ? (
              <>
                <span className="inline-block w-4 h-4 mr-2 border-t-2 border-white rounded-full animate-spin"></span>
                Enhancing...
              </>
            ) : (
              <>
                <Wand2 className="inline-block w-4 h-4 mr-2" />
                <span>AI Magic</span>
              </>
            )}
          </button>
        </div>
        
        <div className="mb-4 relative">
          <div className="quill-custom">
            <ReactQuill
              ref={quillRef}
              value={content}
              onChange={(newContent) => onContentChange(index, newContent)}
              modules={modules}
              placeholder={content.trim() === ''||'<p><br><p>' ? `Provide a brief overview of your ${contentType}, including goals, methods, and outcomes.` : ''}
              theme="snow"
            />
            {animateAIRewrite && (
              <div className="animate-enhancement">
                <div 
                  className="absolute inset-0 bg-gradient-to-r from-blue-100 via-blue-300 to-blue-100 opacity-30 pointer-events-none" 
                  style={{ 
                    width: `${animationProgress}%`, 
                    transition: 'width 0.3s ease-out',
                    backgroundSize: '200% 100%',
                    animation: 'shimmer 2s infinite linear'
                  }}
                />
                {isGenerating && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="px-4 py-2 bg-white rounded-md shadow-md flex items-center">
                      <div className="animate-spin w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full mr-2"></div>
                      <span className="text-sm text-gray-700">Enhancing content...</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            
            {selectionPosition && !isGenerating && (
              <div 
                className="selection-floating-container"
                style={{
                  top: `${selectionPosition.top}px`,
                  left: `${selectionPosition.left}px`,
                }}
              >
                {!showSelectionPromptInput ? (
                  <div className="selection-buttons-row">
                    <button
                      className="ai-enhance-button"
                      onClick={() => handleAIRewrite('', 'selection_rewrite')}
                    >
                      <Wand2 className="w-4 h-4 mr-2" />
                      AI Enhance
                    </button>
                    <button 
                      className="selection-dropdown-toggle"
                      onClick={() => {
                        const currentSelection = getSelectedText();
                        setStoredSelection(currentSelection);
                        setShowSelectionPromptInput(true);
                      }}
                      aria-label="Custom instructions"
                      title="Custom instructions"
                    >
                      <ChevronDown className="w-5 h-5" />
                    </button>
                  </div>
                ) : (
                  <div className="selection-input-container">
                    <textarea
                      ref={textareaRef}
                      className="selection-prompt-input"
                      placeholder="Instructions for enhancing..."
                      value={selectionCustomPrompt}
                      onChange={(e) => {
                        setSelectionCustomPrompt(e.target.value);
                      }}
                      autoFocus
                      style={{ height: textareaHeight, minHeight: '60px', resize: 'none' }}
                      onKeyDown={(e) => {
                        // Shift+Enter to submit, regular Enter for new line
                        if (e.key === 'Enter' && e.shiftKey) {
                          // This is the default behavior (new line), so no need to do anything
                          return;
                        } else if (e.key === 'Enter' && !e.shiftKey) {
                          // Shift+Enter triggers enhance
                          e.preventDefault();
                          handleAIRewrite(selectionCustomPrompt, 'selection_rewrite');
                        }
                      }}
                    />
                    {storedSelection.text && (
                      <div className="mt-1 mb-2 text-xs text-gray-500 italic">
                        Enhancing: "{storedSelection.text.length > 30 ? storedSelection.text.substring(0, 30) + '...' : storedSelection.text}"
                      </div>
                    )}
                    
                    <div className="preset-prompts mt-2 mb-2">
                      <span className="text-xs text-gray-600 mb-1 block">Quick prompts:</span>
                      <div className="flex flex-wrap gap-2">

                      <button 
                          className="preset-prompt-button px-2 py-1 text-xs bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
                          onClick={() => setSelectionCustomPrompt("Expand point")}
                        >
                          ✨ Expand Point
                        </button>
                        <button 
                          className="preset-prompt-button px-2 py-1 text-xs bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
                          onClick={() => setSelectionCustomPrompt("Make this more concise")}
                        >
                          ✂️ Shorten
                        </button>
                        <button 
                          className="preset-prompt-button px-2 py-1 text-xs bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
                          onClick={() => setSelectionCustomPrompt("Elaborate with more details")}
                        >
                          🔍 Elaborate
                        </button>
                        <button 
                          className="preset-prompt-button px-2 py-1 text-xs bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
                          onClick={() => setSelectionCustomPrompt("Add specific metrics or examples")}
                        >
                          📊 Add metrics
                        </button>
                        
                      </div>
                    </div>
                    
                    <div className="selection-prompt-buttons">
                      <button 
                        className="selection-prompt-button selection-apply-button"
                        onClick={() => {
                          console.log('Using stored selection for enhancement:', storedSelection);
                          handleAIRewrite(selectionCustomPrompt, 'selection_rewrite');
                        }}
                      >
                        <Wand2 className="w-3 h-3 mr-1" />
                        Enhance
                      </button>
                      <button 
                        className="selection-prompt-button selection-cancel-button"
                        onClick={() => {
                          // Clear all highlighting from the document when canceling
                          clearAllHighlighting();
                          
                          // Make sure all highlighting state is reset
                          setShowSelectionPromptInput(false);
                          setSelectionCustomPrompt('');
                          setStoredSelection({ text: '', range: null });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Accept/Reject UI */}
        {showAcceptReject && !isGenerating && (
          <div className="mt-4 p-3 bg-gray-50 rounded border border-gray-200 shadow-sm">
            <div className="text-sm text-gray-600 mb-2">
              <strong>AI Enhancement Applied</strong>
              
            </div>
            <div className="flex gap-2">
              <button 
                onClick={acceptChanges}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                Accept
              </button>
              <button 
                onClick={rejectChanges}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                Reject
              </button>
            </div>
          </div>
        )}

        <div className="mt-2">
          <span className="text-sm text-gray-500 mt-2 block">
            You can undo your mistakes by pressing <strong>Ctrl + Z</strong> on your keyboard, unlike in life.
          </span>
        </div>
      </div>
    );
};

export default AIEnhancedEditor;