import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { signUp } from '../../Models/authModel';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import AuthSidebar from './AuthSidebar';
import './authStyles.css';

const SignUpView = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [verificationSent, setVerificationSent] = useState(false);
    const [verificationEmail, setVerificationEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);  // Start loading
        
        try {
            if(password !== confirmPassword){
                setError("Passwords do not match");
                setIsLoading(false);
                return;
            }
            const response = await signUp(name, email, userName, password);

            if(response.success){
                if (response.requires_verification) {
                    // Show verification message instead of redirecting
                    setVerificationSent(true);
                    setVerificationEmail(response.email);
                } else {
                    // Google sign-in users are auto-verified, redirect them
                    navigate('/home');
                }
            }
            else{
                setError(response.message);
            }
        } catch (error) {
            setError('An error occurred during sign up');
        } finally {
            setIsLoading(false);  // Stop loading
        }
    };

    const handleLogin = () => {
        navigate('/login');
    };

    // If verification email has been sent, show a different UI
    if (verificationSent) {
        return (
            <div className="flex min-h-screen auth-container">
                <AuthSidebar />
                <div className="w-full lg:w-1/2 xl:w-2/5 flex items-center justify-center p-4 md:p-8">
                    <div className="w-full max-w-md neomorphic-card p-8 space-y-6">
                        <div className="text-center mb-6">
                            <div className="genie-glow inline-block">
                                <h1 className="text-4xl font-bold text-indigo-700 mb-2 flex items-center justify-center">
                                    <span className="text-5xl mr-2">🧞</span>ResumeGenie
                                </h1>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="mx-auto w-20 h-20 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </div>
                            <h2 className="text-2xl font-bold text-indigo-700">Verify Your Email</h2>
                            <p className="mt-4 text-gray-600">
                                We've sent a verification link to <span className="font-semibold">{verificationEmail}</span>
                            </p>
                            <p className="mt-2 text-gray-600">
                                Please check your inbox and click the link to verify your account.
                            </p>
                            <p className="mt-2 text-sm text-gray-500">
                                The verification link will expire in 48 hours.
                            </p>
                            <button
                                onClick={() => navigate('/login')}
                                className="neomorphic-button mt-6 px-6 py-2 font-medium text-white focus:outline-none"
                            >
                                Go to Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex min-h-screen auth-container">
            {/* Left side with benefits and testimonials */}
            <AuthSidebar />
            
            {/* Right side with signup form */}
            <div className="w-full lg:w-1/2 xl:w-2/5 flex items-center justify-center p-4 md:p-8">
                <div className="w-full max-w-md neomorphic-card p-8 space-y-6">
                    <div className="text-center mb-6">
                        <div className="genie-glow inline-block">
                            <h1 className="text-4xl font-bold text-indigo-700 mb-2 flex items-center justify-center">
                                <span className="text-5xl mr-2">🧞</span>ResumeGenie
                            </h1>
                        </div>
                        <p className="text-gray-600">Create your free account</p>
                    </div>
                    
                    {error && (
                        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
                            <p className="text-red-700">{error}</p>
                        </div>
                    )}
                    
                    <div className="flex justify-center">
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                                <span className="ml-2 text-indigo-600">Creating account...</span>
                            </div>
                        ) : (
                            <div className="w-full">
                                <GoogleLogin
                                    onSuccess={async credentialResponse => {
                                        setIsLoading(true);
                                        try {
                                            const decodedToken = jwtDecode(credentialResponse.credential);
                                            const email = decodedToken.email;
                                            const given_name = decodedToken.given_name;
                                            const family_name = decodedToken.family_name;
                                            const name = given_name + ' ' + family_name;
                                            const username = email.split('@')[0];
                                            const password = 'password';
                                            const response = await signUp(name, email, username, password);
                                            if(response.success){
                                                navigate('/home');
                                            }
                                            else{
                                                setError(response.message);
                                            }
                                        } catch (error) {
                                            setError('An error occurred during sign up');
                                        } finally {
                                            setIsLoading(false);
                                        }
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                        setError('Google sign up failed');
                                    }}
                                    theme="filled_blue"
                                    shape="pill"
                                    text="signup_with"
                                    useOneTap
                                />
                            </div>
                        )}
                    </div>
                    
                    <div className="divider">or</div>
                    
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your full name"
                                className="neomorphic-input w-full px-4 py-3 text-gray-700"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email address"
                                className="neomorphic-input w-full px-4 py-3 text-gray-700"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Username</label>
                            <input
                                type="text"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                placeholder="Choose a username"
                                className="neomorphic-input w-full px-4 py-3 text-gray-700"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Create a strong password"
                                className="neomorphic-input w-full px-4 py-3 text-gray-700"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Confirm Password</label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm your password"
                                className="neomorphic-input w-full px-4 py-3 text-gray-700"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="neomorphic-button w-full py-3 font-medium text-white focus:outline-none"
                        >
                            {isLoading ? (
                                <div className="flex items-center justify-center">
                                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                                    <span className="ml-2">Creating account...</span>
                                </div>
                            ) : (
                                'Create Free Account'
                            )}
                        </button>
                    </form>
                    
                    <div className="text-center">
                        <p className="text-sm text-gray-600">
                            Already have an account? <a href="/login" className="text-indigo-600 hover:text-indigo-800 font-medium">Log in</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpView;