import { json } from "react-router-dom";
import currentApiUrl from "./apiUrl";
import { logout, refreshSession } from "./authModel";

// Get the user_id from the session
const getUserIdFromSession = () => {
    try {
        const sessionData = localStorage.getItem('session');
        if (!sessionData) {
            console.error('No session data found');
            return null;
        }
        
        const session = JSON.parse(sessionData);
        if (!session.user_id) {
            console.error('User ID not found in session');
            return null;
        }
        
        // Refresh the session when making API calls
        refreshSession();
        
        return session.user_id;
    } catch (error) {
        console.error('Error getting user ID from session:', error);
        return null;
    }
};

// Reset retry count for each new call
const getResumesWithRetry = async (maxRetries = 3, currentRetry = 0) => {
    // Get user ID at the time of the API call
    const userId = getUserIdFromSession();
    
    if (!userId) {
        console.error('Cannot fetch resumes: No user ID available');
        throw new Error('User authentication required');
    }
    
    try {
        console.log(`Fetching resumes for user: ${userId} (Attempt ${currentRetry + 1}/${maxRetries})`);
        const response = await fetch(`${currentApiUrl}/get_resumes?user_id=${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }
        
        const data = await response.json();
        return data.resumes;
        
    } catch (error) {
        console.error(`Fetch attempt ${currentRetry + 1} failed:`, error);
        
        if (currentRetry < maxRetries - 1) {
            // Wait a bit before retrying (exponential backoff)
            const delay = Math.pow(2, currentRetry) * 500;
            await new Promise(resolve => setTimeout(resolve, delay));
            return getResumesWithRetry(maxRetries, currentRetry + 1);
        } else {
            console.error('Max retries reached. Fetch operation failed:', error);
            throw error;
        }
    }
};

export const getResumes = async () => {
    try {
        return await getResumesWithRetry();
    } catch (error) {
        console.error('Error fetching resumes:', error);
        // If there's an authentication error, log the user out
        if (error.message === 'User authentication required') {
            logout();
        }
        return [];
    }
};

export const getJsonResume = async (pdfText) => {

    if (!pdfText) {
        throw new Error('PDF text is required');
    }

    try {
        const response = await fetch(`${currentApiUrl}/get_json_resume`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ resume_text: pdfText }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.json_resume) {
            return data.json_resume;
        } else {
            throw new Error('JSON resume data not found in API response');
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
};

export const deleteResume = async (resumeId) => {
    if (!resumeId) {
        throw new Error('Resume ID is required');
    }
    
    // Get user ID at the time of the API call
    const userId = getUserIdFromSession();
    
    if (!userId) {
        console.error('Cannot delete resume: No user ID available');
        throw new Error('User authentication required');
    }
    
    try {
        const response = await fetch(`${currentApiUrl}/delete_resume`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                id: resumeId,
                user_id: userId 
            }),
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();

        if (data.success) {
            return true;
        } else {
            throw new Error('Failed to delete resume');
        }
    } catch (error) {
        console.error('Error deleting resume:', error);
        throw error;
    }
};

//write a function to get keywords from only a job description
export const getKeywords = async (jobDescription, resume_id) => {
    if (!jobDescription) {
        throw new Error('Job description is required');
    }

    if(!resume_id){
        throw new Error('Resume data is required');
    }
    
    // Get user ID at the time of the API call
    const userId = getUserIdFromSession();
    
    if (!userId) {
        console.error('Cannot get keywords: No user ID available');
        throw new Error('User authentication required');
    }

    try {
        const response = await fetch(`${currentApiUrl}/get_keywords`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                job_description: jobDescription,
                resume_id: resume_id,
                user_id: userId
            }),
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();
        
        if (data) {
            return data;
        } else {
            throw new Error('Keywords not found in API response');
        }
    } catch (error) {
        console.error('Error getting keywords:', error);
        throw error;
    }
};

export const injectKeywords = async (resumeData, sections, keywords, summary) => {
    console.log('Summary:', summary);
    if (!resumeData) {
        throw new Error('Resume is required');
    }

    if (!sections) {
        throw new Error('Sections are required');
    }
    
    // Get user ID at the time of the API call
    const userId = getUserIdFromSession();
    
    if (!userId) {
        console.error('Cannot inject keywords: No user ID available');
        throw new Error('User authentication required');
    }

    try {
        const response = await fetch(`${currentApiUrl}/inject_keywords`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                resume_data: resumeData, 
                sections: sections, 
                keywords: keywords,
                summary: summary,
                user_id: userId
            }),
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();

        return data;

    } catch (error) {
        console.error('Error injecting keywords:', error);
        throw error;
    }
}

export const getAllResumes = async () => {
    // Get user ID at the time of the API call
    const userId = getUserIdFromSession();
    
    if (!userId) {
        console.error('Cannot get all resumes: No user ID available');
        throw new Error('User authentication required');
    }

    try {
        const url = `${currentApiUrl}/get_all_resumes?user_id=${userId}`;
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();
        const resumeArray = data.resumes.map((resume) => ({
            name: resume.name,
            id: resume._id.toString()
        }));

        console.log('All resumes:', resumeArray);
        return resumeArray;
    } catch (error) {
        console.error('Error fetching all resumes:', error);
        throw error;
    }
};

export const getResume = async (resumeId) => {
    if (!resumeId) {
        throw new Error('Resume ID is required');
    }

    console.log(`getResume called with ID: ${resumeId}`);

    try {
        // Get user_id from session
        const userId = getUserIdFromSession();
        console.log(`User ID from session: ${userId}`);
        
        if (!userId) {
            console.error('User authentication required for getResume');
            throw new Error('User authentication required');
        }
        
        const url = `${currentApiUrl}/get_resume?resume_id=${resumeId}&user_id=${userId}`;
        console.log(`Fetching resume from: ${url}`);
        
        const response = await fetch(url);

        if (!response.ok) {
            const errorText = await response.text();
            console.error(`API error (${response.status}): ${errorText}`);
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();
        console.log('API response received:', data);

        if (data.resume) {
            console.log('Resume found in API response');
            return data.resume;
        } else {
            console.error('Resume not found in API response:', data);
            throw new Error('Resume not found in API response');
        }
    } catch (error) {
        console.error('Error fetching resume:', error);
        throw error;
    }
};