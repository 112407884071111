import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmail } from '../../Models/authModel';
import AuthSidebar from './AuthSidebar';
import './authStyles.css';

const VerifyEmail = () => {
    const [status, setStatus] = useState('verifying'); // verifying, success, error
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const verifyToken = async () => {
            try {
                // Get token from URL query parameters
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams.get('token');

                if (!token) {
                    setStatus('error');
                    setMessage('Invalid verification link. No token provided.');
                    return;
                }

                // Call API to verify token
                const response = await verifyEmail(token);

                if (response.success) {
                    setStatus('success');
                    setMessage(response.message || 'Your email has been successfully verified!');
                } else {
                    setStatus('error');
                    setMessage(response.message || 'Verification failed. Please try again.');
                }
            } catch (error) {
                setStatus('error');
                setMessage('An error occurred during verification. Please try again.');
                console.error('Verification error:', error);
            }
        };

        verifyToken();
    }, [location]);

    const handleGoToLogin = () => {
        navigate('/login');
    };

    return (
        <div className="flex min-h-screen auth-container">
            {/* Left side with benefits and testimonials */}
            <AuthSidebar />
            
            {/* Right side with verification status */}
            <div className="w-full lg:w-1/2 xl:w-2/5 flex items-center justify-center p-4 md:p-8">
                <div className="w-full max-w-md neomorphic-card p-8 space-y-6">
                    <div className="text-center mb-6">
                        <div className="genie-glow inline-block">
                            <h1 className="text-4xl font-bold text-indigo-700 mb-2 flex items-center justify-center">
                                <span className="text-5xl mr-2">🧞</span>ResumeGenie
                            </h1>
                        </div>
                    </div>

                    <div className="text-center">
                        {status === 'verifying' && (
                            <>
                                <div className="mx-auto w-20 h-20 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                                    <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-indigo-600"></div>
                                </div>
                                <h2 className="text-2xl font-bold text-indigo-700">Verifying Your Email</h2>
                                <p className="mt-4 text-gray-600">Please wait while we verify your email address...</p>
                            </>
                        )}

                        {status === 'success' && (
                            <>
                                <div className="mx-auto w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mb-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <h2 className="text-2xl font-bold text-green-600">Email Verified!</h2>
                                <p className="mt-4 text-gray-600">{message}</p>
                                <p className="mt-2 text-gray-600">You can now log in to your account.</p>
                                <button
                                    onClick={handleGoToLogin}
                                    className="neomorphic-button mt-6 px-6 py-2 font-medium text-white focus:outline-none"
                                >
                                    Go to Login
                                </button>
                            </>
                        )}

                        {status === 'error' && (
                            <>
                                <div className="mx-auto w-20 h-20 bg-red-100 rounded-full flex items-center justify-center mb-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                <h2 className="text-2xl font-bold text-red-600">Verification Failed</h2>
                                <p className="mt-4 text-gray-600">{message}</p>
                                <p className="mt-2 text-gray-600">
                                    Please try again or request a new verification email from the login page.
                                </p>
                                <button
                                    onClick={handleGoToLogin}
                                    className="neomorphic-button mt-6 px-6 py-2 font-medium text-white focus:outline-none"
                                >
                                    Go to Login
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail; 