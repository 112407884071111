const productionApiUrl = process.env.REACT_APP_API_URL;
const urlType = process.env.REACT_APP_URL_TYPE;
const localApiUrl = "http://127.0.0.1:8000";
console.log('API URL:', productionApiUrl);
console.log('URL TYPE:', urlType);

// Fix: Use REACT_APP_ prefix for environment variables in React
const currentApiUrl = urlType === 'production' ? productionApiUrl : localApiUrl;



console.log('Current API URL:', currentApiUrl);

export default currentApiUrl;
