import currentApiUrl from "./apiUrl";

export const login = async (username, password,google_signin,name) =>{

    try {

        const response = await fetch(`${currentApiUrl}/login_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                user_name: username,
                password: password,
                google_signin: google_signin,
                name: name
             })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }


        const data = await response.json();



        if (data.success) {

            // Calculate expiration time (3 days from now)
            const currentTime = new Date().getTime();
            const expirationTime = currentTime + (3 * 24 * 60 * 60 * 1000); // 3 days in milliseconds
            
            // Create a session object with user_id, current time, and expiration time
            const session = { 
                user_id: data.user_id, 
                created_at: currentTime,
                expires_at: expirationTime 
            };
            
            // Store the session object in local storage
            localStorage.setItem("session", JSON.stringify(session));
            
            // Set isAuthenticated to true
            localStorage.setItem("isAuthenticated", true);

        

            return { success: true };
        } else {
            // Check if the error is due to unverified account
            if (data.requires_verification) {
                return { 
                    success: false, 
                    message: 'Please verify your email before logging in', 
                    requires_verification: true,
                    email: data.email || username
                };
            }
            return { success: false, message: data.error || 'Login failed' };
        }

    }
    catch (error) {
        return { success: false, message: 'Invalid credentials' };
    }
}


export const logout= ()=>{
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("session");
    // Clear all resume-related data from localStorage
    localStorage.removeItem("current_resume_id");
    localStorage.removeItem("current_resume_data");
    // Clear any other auth-related data that might be stored
    console.log('User logged out, session and resume data cleared');
};

export const isAuthenticated = () => {
    // Check if isAuthenticated flag exists
    const isAuth = localStorage.getItem("isAuthenticated");
    
    // Check if session exists and has a valid user_id
    const sessionData = localStorage.getItem("session");
    
    if (!isAuth || !sessionData) {
        return false;
    }
    
    try {
        const session = JSON.parse(sessionData);
        
        // Check if user_id exists in session
        if (!session.user_id) {
            return false;
        }
        
        // Check if session has expired
        const currentTime = new Date().getTime();
        
        // If expires_at exists, check if the session has expired
        if (session.expires_at && currentTime > session.expires_at) {
            console.log('Session expired');
            // Session expired, clear localStorage
            logout();
            return false;
        }
        
        // If using old session format without expiration, add expiration
        if (!session.expires_at) {
            // Calculate new expiration time (3 days from now)
            const expirationTime = currentTime + (3 * 24 * 60 * 60 * 1000);
            
            // Update session with expiration
            session.created_at = session.time || currentTime;
            session.expires_at = expirationTime;
            delete session.time; // Remove old time property if it exists
            
            // Save updated session
            localStorage.setItem("session", JSON.stringify(session));
        }
        
        return true;
    } catch (error) {
        // If there's an error parsing the session, it's invalid
        console.error('Invalid session data:', error);
        logout();
        return false;
    }
};

export const signUp = async (name,email,username, password) => {
    try {
        const response = await fetch(`${currentApiUrl}/create_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                name: name,
                email: email,
                user_name: username,
                password: password
             })
        });


        const data = await response.json();

        if (data.success) {
            // If Google sign-in, create session as before
            if (data.is_verified) {
                // Calculate expiration time (3 days from now)
                const currentTime = new Date().getTime();
                const expirationTime = currentTime + (3 * 24 * 60 * 60 * 1000); // 3 days in milliseconds
                
                // Create a session object with user_id, current time, and expiration time
                const session = { 
                    user_id: data.user_id, 
                    created_at: currentTime,
                    expires_at: expirationTime 
                };
                
                // Store the session object in local storage
                localStorage.setItem("session", JSON.stringify(session));
                
                // Set isAuthenticated to true
                localStorage.setItem("isAuthenticated", true);
                return { success: true };
            } else {
                // For regular sign-up, return success but indicate verification needed
                return { 
                    success: true, 
                    requires_verification: true,
                    email: email
                };
            }
        } else {
            return { success: false, message: data.error || 'Signup failed' };
        }
    } catch (error) {
        return { success: false, message: error.message || 'An error occurred' };
    }
};

// New functions for email verification
export const verifyEmail = async (token) => {
    try {
        const response = await fetch(`${currentApiUrl}/verify_email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        return { success: false, message: error.message || 'Verification failed' };
    }
};

export const resendVerificationEmail = async (email) => {
    try {
        const response = await fetch(`${currentApiUrl}/resend_verification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        return { success: false, message: error.message || 'Failed to resend verification email' };
    }
};

// Add a function to refresh the session (extend expiration)
export const refreshSession = () => {
    try {
        const sessionData = localStorage.getItem("session");
        if (!sessionData) {
            return false;
        }
        
        const session = JSON.parse(sessionData);
        
        // Check if user_id exists
        if (!session.user_id) {
            return false;
        }
        
        // Calculate new expiration time (3 days from now)
        const currentTime = new Date().getTime();
        const expirationTime = currentTime + (3 * 24 * 60 * 60 * 1000);
        
        // Update session with new expiration
        session.expires_at = expirationTime;
        
        // Save updated session
        localStorage.setItem("session", JSON.stringify(session));
        console.log('Session refreshed, new expiration set');
        
        return true;
    } catch (error) {
        console.error('Error refreshing session:', error);
        return false;
    }
};

// Add a function to get session information for debugging
export const getSessionInfo = () => {
    try {
        const isAuth = localStorage.getItem("isAuthenticated");
        const sessionData = localStorage.getItem("session");
        
        if (!isAuth || !sessionData) {
            return {
                isAuthenticated: !!isAuth,
                hasSession: !!sessionData,
                sessionDetails: null
            };
        }
        
        const session = JSON.parse(sessionData);
        
        // Calculate time remaining until expiration
        const currentTime = new Date().getTime();
        const expiresAt = session.expires_at || 0;
        const timeRemaining = expiresAt - currentTime;
        
        // Convert to days, hours, minutes
        const days = Math.floor(timeRemaining / (24 * 60 * 60 * 1000));
        const hours = Math.floor((timeRemaining % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutes = Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000));
        
        return {
            isAuthenticated: !!isAuth,
            hasSession: true,
            sessionDetails: {
                userId: session.user_id,
                createdAt: new Date(session.created_at || 0).toLocaleString(),
                expiresAt: new Date(expiresAt).toLocaleString(),
                timeRemaining: {
                    days,
                    hours,
                    minutes,
                    total: timeRemaining
                },
                isExpired: timeRemaining <= 0
            }
        };
    } catch (error) {
        console.error('Error getting session info:', error);
        return {
            isAuthenticated: false,
            hasSession: false,
            error: error.message
        };
    }
};

// Debug function to check session status
export const debugSession = () => {
    try {
        console.log('=== SESSION DEBUG ===');
        
        // Check isAuthenticated flag
        const isAuth = localStorage.getItem("isAuthenticated");
        console.log('isAuthenticated:', isAuth);
        
        // Check session data
        const sessionData = localStorage.getItem("session");
        console.log('Session data exists:', !!sessionData);
        
        if (sessionData) {
            try {
                const session = JSON.parse(sessionData);
                console.log('Session parsed successfully');
                console.log('user_id:', session.user_id);
                console.log('created_at:', new Date(session.created_at).toLocaleString());
                console.log('expires_at:', new Date(session.expires_at).toLocaleString());
                
                // Check if session is expired
                const currentTime = new Date().getTime();
                const isExpired = currentTime > session.expires_at;
                console.log('Session expired:', isExpired);
                
                return {
                    isAuthenticated: !!isAuth,
                    sessionExists: true,
                    userId: session.user_id,
                    createdAt: new Date(session.created_at).toLocaleString(),
                    expiresAt: new Date(session.expires_at).toLocaleString(),
                    isExpired
                };
            } catch (error) {
                console.error('Error parsing session:', error);
                return {
                    isAuthenticated: !!isAuth,
                    sessionExists: true,
                    parseError: true,
                    error: error.message
                };
            }
        }
        
        return {
            isAuthenticated: !!isAuth,
            sessionExists: false
        };
    } catch (error) {
        console.error('Error in debugSession:', error);
        return {
            error: error.message
        };
    } finally {
        console.log('=== END SESSION DEBUG ===');
    }
};