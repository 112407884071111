import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, logout, refreshSession, debugSession } from '../../Models/authModel';
import BaseResume from './Pages/BaseResume';
import CustomResume from './Pages/CustomResume';
import { Sidebar, SidebarItem } from './SideBar';
import { Layout, FileText, LogOut, Brain, User } from 'lucide-react';
import LogOutPage from './Pages/LogOut';
import FeedbackForm from './Pages/FeedbackForm';
import UserProfile from './Pages/UserProfile';


const Home = () => {
  const [auth, setAuth] = useState(false);
  const [selectedOption, setSelectedOption] = useState('base');
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [sessionStatus, setSessionStatus] = useState(null);
  const navigate = useNavigate()
  const [showDebug, setShowDebug] = useState(false);

  // Check authentication status when component mounts
  useEffect(() => {
    const checkAuth = () => {
      try {
        // Debug session status
        const status = debugSession();
        setSessionStatus(status);
        
        // Check if authenticated
        const isAuth = isAuthenticated();
        setAuth(isAuth);
        
        if (!isAuth) {
          // If not authenticated, redirect to login
          console.error('Not authenticated, redirecting to login');
          navigate('/login');
        } else {
          // If authenticated, refresh the session to extend expiration
          console.log('Authenticated, refreshing session');
          refreshSession();
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        // If there's an error, assume not authenticated
        setAuth(false);
        navigate('/login');
      } finally {
        setIsCheckingAuth(false);
      }
    };
    
    checkAuth();
  }, [navigate]);

  const handleLogout = () => {
    logout();
    setAuth(false);
    navigate('/login');
  };

  const renderContent = () => {
    switch (selectedOption) {
      case 'base':
        return <BaseResume />;
      case 'customize':
        return <CustomResume />;
      case 'feedback':
        return <FeedbackForm />;
      // case 'profile':
      //   return <UserProfile />;
      default:
        return <h2 className="text-2xl font-bold">Select an option</h2>;
    }
  };

  const [showConfirm, setShowConfirm] = useState(false);

  const handleConfirmLogout = () => {
    setShowConfirm(true);
  };

  const confirmLogout = (confirm) => {
    if (confirm) {
      handleLogout();
    }
    setShowConfirm(false);
  };

  return (
    <>
      {isCheckingAuth ? (
        // Show loading indicator while checking authentication
        <div className="flex items-center justify-center h-screen">
          <div className="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-blue-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
        </div>
      ) : (
        <div className="flex">
          {/* Add session debug info in development */}
          {process.env.NODE_ENV === 'development' && sessionStatus && (
            <>
              <button 
                onClick={() => setShowDebug(!showDebug)}
                className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-full shadow-lg z-50"
              >
                {showDebug ? 'Hide' : 'Debug'}
              </button>
              {showDebug && (
                <div className="fixed bottom-16 right-4 bg-white p-4 rounded shadow-lg text-xs max-w-xs overflow-auto z-50" style={{ maxHeight: '200px' }}>
                  <h3 className="font-bold mb-2">Session Debug</h3>
                  <pre>{JSON.stringify(sessionStatus, null, 2)}</pre>
                </div>
              )}
            </>
          )}
          
          <Sidebar setSelectedOption={setSelectedOption}>
            <SidebarItem
              icon={<Layout size={20} />}
              text="All Resumes"
              option="base"
              active={selectedOption === 'base'}
            />
            <SidebarItem
              icon={<Brain size={20} />}
              text="Customize Resume"
              option="customize"
              active={selectedOption === 'customize'}
            />
            
            
            {/* <SidebarItem
              icon={<User size={20} />}
              text="Profile"
              option="profile"
              active={selectedOption === 'profile'}
            /> */}

            <SidebarItem
              icon={<FileText size={20} />}
              text="Feedback"
              option="feedback"
              active={selectedOption === 'feedback'}
            />


          </Sidebar>
          <div className="flex-1 p-4">
            {renderContent()}
            {showConfirm && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-4 rounded shadow-lg">
                  <h2 className="text-xl font-bold mb-4">Confirm Logout</h2>
                  <p className="mb-4">Are you sure you want to logout?</p>
                  <div className="flex justify-end">
                    <button 
                      className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                      onClick={() => confirmLogout(false)}
                    >
                      Cancel
                    </button>
                    <button 
                      className="bg-red-500 text-white px-4 py-2 rounded"
                      onClick={() => confirmLogout(true)}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Home;